import {default as NextLink} from "next/link"
import {
    chakra,
    Box,
    Stack,
    StackDivider,
    Heading,
    Link,
    AspectRatio,
  } from "@chakra-ui/react"
  import { ChakraImage } from "./atoms/ChakraImage"

const RPContainer = chakra(Box)
const RPBox = chakra(Stack)

export default function RelatedPosts({tagPosts, postId}) {
    const rpContent = tagPosts[0]?.posts?.nodes
    .filter(post => post.id !== postId)
    .slice(0, 4)
    return (
        <RPContainer flexWrap="nowrap" direction="row" mx="auto" my={4}>
      <RPBox
        divider={<StackDivider borderColor="gray.200" />}
        overflow="hidden"
        background="white"
        borderRadius="md"
        boxShadow="md"
        className="bio"
        justifyContent="center"
        pb={4}
      >
        <Heading
          as="h3"
          size="md"
          mb="0"
          px={8}
          pt={3}
          pb={2}
          align="left"
          fontWeight="500"
        >
          Related Posts
        </Heading>
        <Stack
          direction="row"
          px={8}
          pb={3}
          pt={4}
          spacing="20px"
        >
          {rpContent &&
            rpContent.map((post, index) => {
              return (
                <Stack key={`rp_${index}`}>
                  <Link as={NextLink} href={post.uri} itemProp="url">
                      <AspectRatio ratio={4 / 3 }>

                    <ChakraImage
                      src={
                        post.featuredImage.node.sourceUrl
                      }
                      alt={post.featuredImage.node.altText}
                      layout="fill"
                      objectFit="cover"
                    />
                      </AspectRatio>
                  </Link>
                  <Link as={NextLink} href={post.uri} itemProp="url">
                    <Heading as="h3" size="xs" fontWeight="semibold">
                      {post.title}
                    </Heading>
                  </Link>
                </Stack>
              )
            })}
        </Stack>
      </RPBox>
    </RPContainer>
    )
}
