import Head from "next/head";

export default function Seo({title, description, schemaMarkup}) {
    return (
        <Head>
        <title>{title}</title>
        <link rel="icon" href="/idsc-favicon.png" />
        {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
      <script
        id="s9-sdk"
        async
        defer
        content="b7fb488c1bf04b31ab1d5a07701e1686"
        src="//cdn.social9.com/js/socialshare.min.js"
      ></script>
      </Head>
    )
}
