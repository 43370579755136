import React from "react"
import { HStack, Link, Box, chakra, Icon } from "@chakra-ui/react"
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTumblr,
  FaLinkedinIn,
  FaYoutube,
  FaSpotify,
} from "react-icons/fa"

const socialMediaData = [
  {
    network: "facebook",
    url: "https://www.facebook.com/idrivesocal",
    icon: "FaFacebookF",
    primary: "facebook.500",
  },
  {
    network: "twitter",
    url: "https://twitter.com/idrivesocal",
    icon: "FaTwitter",
    primary: "twitter.500",
  },
  {
    network: "pinterest",
    url: "https://www.pinterest.com/iDriveSoCal",
    icon: "FaPinterestP",
    primary: "red.500",
  },
  {
    network: "instagram",
    url: "https://www.instagram.com/idrivesocal",
    icon: "FaInstagram",
    primary: "blue.500",
  },
  {
    network: "linkedin",
    url: "https://www.linkedin.com/company/idrivesocal",
    icon: "FaLinkedinIn",
    primary: "linkedin.500",
  },
  {
    network: "tumblr",
    url: "https://idrivesocal.tumblr.com",
    icon: "FaTumblr",
    primary: "blue.700",
  },
  {
    network: "youtube",
    url: "https://www.youtube.com/channel/UC0HZusGNEBUl3lO7AdprFdQ",
    icon: "FaYoutube",
    primary: "red.500",
  },
  {
    network: "spotify",
    url: "https://open.spotify.com/show/6wKZT2vrGr15wcsR3RjaR9",
    icon: "FaSpotify",
    primary: "whatsapp.500",
  },
]

const findIcon = network => {
  switch (network) {
    case "facebook":
      return FaFacebookF
    case "twitter":
      return FaTwitter
    case "pinterest":
      return FaPinterestP
    case "instagram":
      return FaInstagram
    case "linkedin":
      return FaLinkedinIn
    case "youtube":
      return FaYoutube
    case "spotify":
      return FaSpotify
    case "tumblr":
      return FaTumblr
  }
}

const SocialIcon = chakra(Icon, {
  baseStyle: {
    color: "white",
  },
})

const SocialIcons = () => {
  return (
    <HStack mr="15px" spacing="1">
      {socialMediaData &&
        socialMediaData.map(social => {
          return (
            <Box
              key={social.icon}
              display="flex"
              justifyContent="center"
              alignItems="center"
              as={Link}
              href={social.url}
              isExternal
              w="2rem"
              h="2rem"
              borderRadius="5px"
            >
              <SocialIcon
                as={findIcon(social.network)}
                _hover={{ color: social.primary }}
                boxSize={4}
              />
            </Box>
          )
        })}
    </HStack>
  )
}

export { SocialIcons }
