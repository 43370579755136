import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { FaSearch } from "react-icons/fa"
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react"

export default connectSearchBox(({ refine, currentRefinement, onFocus }) => (
  <Box as="form">
    <InputGroup maxWidth="180px">
      <Input
        size="md"
        // className="SearchInput"
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
        variant="filled"
        bgColor="#14354E"
        color="white"
        _placeholder={{
          color: "white",
        }}
        _focus={{
          bgColor: "#14354E",
          color: "white",
        }}
        _hover={{
          bgColor: "#14354E",
          color: "white",
        }}
      />
      <InputRightElement
        children={
          <Icon as={FaSearch} transform="rotateY(180deg)" color="white" />
        }
      />
    </InputGroup>
  </Box>
))
