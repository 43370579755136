import { useState } from "react";
import { Box, Flex, chakra, Link, Image as CImage } from "@chakra-ui/react";
import { default as NextLink } from "next/link";
import Image from "next/image";
import Burger from "./navigation/Burger";
import Search from "./search";
import SocialMedia from "./SocialMedia";

const searchIndices = [{ name: `Posts`, title: `Posts` }];

const HeaderContainer = ({ children }) => {
  return (
    <Box
      id="headerContainer"
      bg="white"
      mb="6"
      position="sticky"
      top="0"
      zIndex="1"
    >
      {children}
    </Box>
  );
};

const ChakraImage = chakra(Image, {
  shouldForwardProp: (prop) => ["width", "height", "src", "alt"].includes(prop),
});

const ChakraLink = chakra(NextLink);

const TopHeader = ({ children }) => {
  return <Box bg="brand.800">{children}</Box>;
};

const MainHeader = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
      bg="white"
      w="90%"
      my="0"
      mx="auto"
      overflow="hidden"
      sx={activeLink}
    >
      {children}
    </Box>
  );
};

const activeLink = {
  "nav [aria-current]": {
    color: "brand.600",
    "&:before": {
      content: "''",
      position: "absolute",
      top: "10px",
      left: "0",
      height: "2px",
      backgroundColor: "brand.600",
      width: "100%",
      transform: "translateY(24px)",
      opacity: { base: "0", md: "1" },
      transition: "0.5s ease",
    },
  },
};

const NavMenu = ({ children, isMenuOpen }) => {
  return (
    <Flex
      as="nav"
      display="flex"
      position={{ base: "fixed", md: "static" }}
      top={{ base: "0", md: "unset" }}
      right={{ base: "0", md: "unset" }}
      height={{ base: "100vh", md: "unset" }}
      width={{ base: "300px", md: "unset" }}
      flexDirection={{ base: "column", md: "row" }}
      flexWrap="wrap"
      alignItems="center"
      pt={{
        base: `6rem`,
        md: "unset",
      }}
      transition="transform 0.3s ease-in-out"
      background={{ base: "white", md: "unset" }}
      zIndex={{ base: "1", md: "auto" }}
      transform={{ base: isMenuOpen, md: "unset" }}
    >
      {children}
    </Flex>
  );
};

const NavLink = chakra(Link, {
  baseStyle: {
    marginLeft: { base: "unset", md: "6" },
    textTransform: "uppercase",
    textDecoration: "none",
    fontWeight: "600",
    fontSize: "md",
    color: "rgba(40,40,40, 0.6)",
    letterSpacing: "1px",
    transition: "0.5s ease",
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      top: "10px",
      left: "0",
      height: "2px",
      backgroundColor: "brand.600",
      width: "100%",
      transform: "translateY(40px)",
      opacity: "0",
      transition: "0.5s ease",
    },
    _hover: {
      color: "brand.600",
      textDecoration: "none",
      "&:before": {
        opacity: { base: "0", md: "1" },
        transform: "translateY(24px)",
      },
    },
  },
});

export const Header = (props) => {
  // const headerData = data.menuData.nodes.find(menu => menu.slug === "main")
  const [open, setOpen] = useState(false);
  const isMenuOpen = open ? "translateX(0)" : "translateX(100%)";

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <HeaderContainer>
      <TopHeader>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          py="1"
          w="90%"
          my="0"
          mx="auto"
        >
          <SocialMedia />
          <Search indices={searchIndices} />
        </Box>
      </TopHeader>
      <MainHeader>
        <ChakraLink href="/" _focus={{ boxShadow: "none" }}>
          <a>
            <Box py={4}>
              <ChakraImage
                width="210px"
                height="49.69px"
                layout="intrinsic"
                src="/idsc-logo.png"
              />
            </Box>
          </a>
        </ChakraLink>
        <Burger
          open={open}
          menuItems={props?.menu?.nodes}
          onChildClick={toggleMenu}
        />
        <NavMenu isMenuOpen={isMenuOpen}>
          {props?.menu?.nodes.map((item) => (
            <MenuItem {...item} key={item.id.toString()} />
          ))}
        </NavMenu>
      </MainHeader>
    </HeaderContainer>
  );
};

const MenuItem = ({ path, label }) => {
  return <NavLink href={path}>{label}</NavLink>;
};
