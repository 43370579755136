import { chakra } from '@chakra-ui/react'

const ChakraBurger = chakra("div", {
    baseStyle: {
      width: "2rem",
      height: "2rem",
      zIndex: "20",
      display: { base: "flex", md: "none" },
      justifyContent: "space-around",
      flexDirection: "column",
      flexWrap: "nowrap",
      "div": {
        width: "2rem",
        height: "0.25rem",
        backgroundColor: "gray.700",
        borderRadius: "10px",
        transformOrigin: "1px",
        transition: "all 0.3s linear",
      },
    }
  })
  
  const BurgerLayer = chakra("div")
  
  const Burger = ({open, onChildClick}) => {
    
    return (
        <ChakraBurger open={open} onClick={onChildClick} >
          <BurgerLayer transform={open ? 'rotate(45deg)' : 'rotate(0)'} />
          <BurgerLayer
            transform={open ? 'translateX(100%)' : 'translateX(0)'}
            opacity={open ? 0 : 1}
          />
          <BurgerLayer transform={open ? 'rotate(-45deg)' : 'rotate(0)'} />
        </ChakraBurger>
    )
  }
  
  export default Burger