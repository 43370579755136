import {
    chakra,
    Stack,
    StackDivider,
    Box,
    Heading,
    Image,
    Text,
    Link,
    Icon,
  } from "@chakra-ui/react"
  import {ChakraImage} from "./atoms/ChakraImage"
  import { FaLinkedinIn } from "react-icons/fa"

const BioContainer = chakra(Box)
const BioBox = chakra(Stack)

export default function Bio( {author} ) {
    return (
        <BioContainer flexWrap="nowrap" direction="row" mx="auto" my={4}>
      <BioBox
        divider={<StackDivider borderColor="gray.200" />}
        overflow="hidden"
        background="white"
        borderRadius="md"
        boxShadow="md"
        className="bio"
        justifyContent="center"
        pb={4}
      >
        <Heading
          as="h3"
          size="md"
          mb="0"
          px={8}
          pt={3}
          pb={2}
          align="left"
          fontWeight="500"
        >
          About The Author
        </Heading>
        <Stack direction="row" px={8} pb={3} pt={4} spacing="24px">
          {author?.avatar?.url && (
            <Box w={3 / 12}>
              <ChakraImage
                alt={author?.name || ``}
                className="bio-avatar"
                width={340}
                height={340}
                layout="responsive"
                src={author?.avatar?.url}
              />
            </Box>
          )}
          {author?.name && (
            <Box w={9 / 12}>
              <Heading as="h4" size="sm" fontWeight="500" pb={2}>
                {author.name}
              </Heading>
              <Text fontSize="0.8rem">{author?.description || null}</Text>
              <Link
                href={author.seo.social.linkedIn}
                isExternal
                color="gray.400"
                _hover={{ color: "gray.600" }}
              >
                <Icon as={FaLinkedinIn} />
              </Link>
            </Box>
          )}
        </Stack>
      </BioBox>
    </BioContainer>
    )
}
