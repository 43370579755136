import React from "react"
import { default as NextLink } from "next/link"
import parse from "html-react-parser"
import { Link, Box, Stack, Button, chakra } from "@chakra-ui/react"
import { FaChevronRight, FaChevronLeft } from "react-icons/fa"

const PostNavContainer = chakra(Box)

const PostNav = ({ previous, next }) => {
  return (
    <PostNavContainer
      as="nav"
      flexWrap="nowrap"
      direction="row"
      mx="auto"
      my={4}
    >
      <Stack
        as="ul"
        direction="row"
        listStyleType="none"
        justify="space-between"
        fontSize="sm"
        color="brand.400"
      >
        <Stack>
          {previous && (
            <>
              <Button
                w="min-content"
                colorScheme="brand"
                leftIcon={<FaChevronLeft />}
              >
                <Link
                  as={GatsbyLink}
                  to={previous.uri}
                  rel="prev"
                  _hover={{ color: "gray.700", textDecoration: "none" }}
                >
                  Previous
                </Link>
              </Button>
              <Link
                as={GatsbyLink}
                to={previous.uri}
                rel="prev"
                _hover={{ color: "gray.700", textDecoration: "none" }}
              >
                {parse(previous.title)}
              </Link>
            </>
          )}
        </Stack>

        <Stack>
          {next && (
            <>
              <Button
                w="min-content"
                colorScheme="brand"
                alignSelf="flex-end"
                rightIcon={<FaChevronRight />}
              >
                <Link
                  as={NextLink}
                  to={next.uri}
                  rel="next"
                  _hover={{ color: "gray.700", textDecoration: "none" }}
                >
                  Next
                </Link>
              </Button>
              <Link
                as={NextLink}
                to={next.uri}
                rel="next"
                _hover={{ color: "gray.700", textDecoration: "none" }}
              >
                {parse(next.title)}
              </Link>
            </>
          )}
        </Stack>
      </Stack>
    </PostNavContainer>
  )
}

export default PostNav
