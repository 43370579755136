import { default as NextLink } from "next/link"
import { useRouter } from 'next/router'
import parse from "html-react-parser"
import { Link, Box, Stack, Button, chakra } from "@chakra-ui/react"
import { FaChevronRight, FaChevronLeft } from "react-icons/fa"

const ArchiveNavContainer = chakra(Box)

export default function ArchiveNav ({
  hasPrevious,
  hasNext,
  currentPage,
  archivePath,
  archiveType,
  endCursor,
  startCursor,
}) {
  const router = useRouter()
  console.log(router)
  let previousUri = "/"
  let nextUri = "/"
  if (Number(router.query.p) === 2 && router.pathname === "/page" ) {
    previousUri = "/"
  } else if (hasPrevious && router.pathname === "/page") {
    previousUri = `/page?p=${Number(router.query.p) -1}&last=10&start=${startCursor}`
  } else if ( hasPrevious && router.pathname !== "/events" && router.pathname !== "/events/[slug]" && Number(router.query.p) !== 2 ) {
    previousUri = `${router.asPath.split('?')[0]}?p=${Number(router.query.p) -1}&last=10&start=${startCursor}`
  } else if (hasPrevious && router.pathname === "/events/[slug]" && router.query.slug === "2") {
    previousUri = `/events`
  } else if (hasPrevious && router.pathname === "/events/[slug]" && router.query.slug !== "2") {
    previousUri = `/events/${Number(router.query.slug) -1}`
  }

  if (hasNext && router.asPath === "/") {
    nextUri =`/page?p=2&first=10&end=${endCursor}`
  } else if (hasNext && router.pathname === "/page") {
    nextUri = `/page?p=${Number(router.query.p) +1}&first=10&end=${endCursor}`
  } else if (hasNext && router.pathname !== "/events" && router.pathname !== "/events/[slug]" && router.query.p === undefined) {
    nextUri = `${router.asPath.split('?')[0]}?p=2&first=10&end=${endCursor}`
  } else if (hasNext && router.pathname !== "/events" && router.query.p !== undefined) {
    nextUri = `${router.asPath.split('?')[0]}?p=${Number(router.query.p) +1}&first=10&end=${endCursor}`
  } else if (hasNext && router.asPath === "/events") {
    nextUri = `/events/2`
  } else if (hasNext && router.pathname === "/events/[slug]") {
    nextUri = `/events/${Number(router.query.slug) +1}`
  }
  return (
    <ArchiveNavContainer
      as="nav"
      flexWrap="nowrap"
      direction="row"
      mx="auto"
      my={4}
    >
      <Stack
        as="ul"
        direction="row"
        listStyleType="none"
        justify="center"
        fontSize="sm"
        color="brand.400"
      >
        <Stack>
          {hasPrevious && (
            <>
              <Link
                as={NextLink}
                href={previousUri}
                rel="prev"
                _hover={{ color: "gray.700", textDecoration: "none" }}
              >
                <a>
                <Button
                  colorScheme="brand"
                  leftIcon={<FaChevronLeft />}
                  sx={{ span: { margin: "0" } }}
                />
                </a>
              </Link>
            </>
          )}
        </Stack>

        <Stack>
          {hasNext && (
            <>
              <Link
                as={NextLink}
                href={nextUri}
                rel="next"
                _hover={{ color: "gray.700", textDecoration: "none" }}
              >
                <a>
                <Button
                  colorScheme="brand"
                  alignSelf="flex-end"
                  rightIcon={<FaChevronRight />}
                  sx={{ span: { margin: "0" } }}
                />
                </a>
              </Link>
            </>
          )}
        </Stack>
      </Stack>
    </ArchiveNavContainer>
  )
}
