import { Flex, Button, Link } from '@chakra-ui/react'

export default function CtaBar({cta}) {
    return (
        <Flex
      w="100vw"
      mx="auto"
      background="white"
      alignItems="center"
      justifyContent="center"
      position="sticky"
      bottom="0"
      left="0"
      py="5"
    >
      <Button as={Link} colorScheme="green" href={cta.callToActionLink} isExternal >{ cta.callToAction }</Button>
    </Flex>
    )
}
