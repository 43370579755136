import { default as NextLink } from "next/link"
import { SocialIcons } from "./socialIcons"
import { Box, Flex, Text, chakra, Link } from "@chakra-ui/react"

const TopFooter = ({ children }) => {
  return (
    <Box mt={8} py="6" px="5%" background="gray.800" color="white">
      {children}
    </Box>
  )
}

const BottomFooter = ({ children }) => {
  return (
    <Flex
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      px="5%"
      color="gray.400"
      background="black"
    >
      {children}
    </Flex>
  )
}

const NavMenu = ({ children }) => {
  return (
    <Flex as="nav" display="flex" flexDirection="column" flexWrap="wrap">
      {children}
    </Flex>
  )
}

const NavLink = chakra(Link, {
  baseStyle: {
    textTransform: "uppercase",
    textDecoration: "none",
    fontWeight: "600",
    color: "#fff",
    letterSpacing: "1px",
    transition: "0.5s ease",
    position: "relative",
    _hover: {
      color: "brand.600",
      textDecoration: "none",
    },
  },
})


export const Footer = (props) => {
  // const menuData = useSiteMenuData()
  // const footerData = menuData.nodes.find(menu => menu.slug === "secondary")

  return (
    <>
      <TopFooter>
        {/* <NavMenu>
          {props.menu && props?.menu?.nodes.map(item => (
            <MenuItem {...item} key={item.id.toString()}></MenuItem>
          ))}
        </NavMenu> */}
      </TopFooter>
      <BottomFooter>
        <Text fontSize="sm" py="5">
          © 2017-{new Date().getFullYear()} iDriveSoCal. All rights reserved.
        </Text>
        <SocialIcons></SocialIcons>
      </BottomFooter>
    </>
  )
}

const MenuItem = ({ path, label }) => {
  return <NavLink as={NextLink} href={path}>{label}</NavLink>
}
