import { Box, chakra, Stack } from "@chakra-ui/react";
import { Header } from "./header";
import { Footer } from "./footer";
import SideBar from "./sidebar";
import Bio from "./Bio";
import RelatedPosts from "./RelatedPosts";
import CtaBar from "./CtaBar";
import ArchiveNav from "./ArchiveNav"
import PostNav from "./PostNav"
// import Popup from "./Popup/Popup"

const SiteContainer = chakra(Box);
const ContentContainer = chakra(Stack);
const MainArticle = chakra("article");

export default function Layout({
  children,
  menus,
  author,
  tagPosts,
  postId,
  cta,
  hasNext,
  hasPrevious,
  startCursor,
  endCursor
}) {
  const headerData = menus.nodes.find((menu) => menu.slug === "main");
  const footerData = menus.nodes.find((menu) => menu.slug === "secondary");
  return (
    <>
      <SiteContainer
        width={{ base: "100%", lg: "90%" }}
        maxW="1280"
        mx="auto"
        justifyContent="center"
        p="0"
        position="relative"
        boxShadow={{ sm: null, md: "base" }}
      >
        <Header menu={headerData.menuItems} />
        <Stack
          as="main"
          direction="row"
          spacing={{ base: "1rem", md: "3rem" }}
          mx="auto"
          flexWrap="nowrap"
          bgColor="gray.200"
          width={{ base: "95%", sm: "90%" }}
        >
          <Box width={{ base: "100%", lg: 3 / 4 }}>
            <ContentContainer direction="row">
              <MainArticle
                overflow="hidden"
                background="white"
                borderRadius="md"
                borderColor="blue.400"
                borderTop="8px solid #135d9b"
                boxShadow="md"
                width="100%"
              >
                {children}
              </MainArticle>
            </ContentContainer>
            {/* {(previous || next) && <PostNav previous={previous} next={next} />} */}
            {(hasPrevious || hasNext) && (
              <ArchiveNav
                hasPrevious={hasPrevious}
                hasNext={hasNext}
                endCursor={endCursor}
                startCursor={startCursor}
                // currentPage={currentPage}
                // pageCount={pageCount}
                // archivePath={archivePath}
                // archiveType={archiveType}
              />
            )}
            {author && <Bio author={author} />}
            {tagPosts?.length > 0 && <RelatedPosts tagPosts={tagPosts} postId={postId} />}
          </Box>
          <SideBar />
        </Stack>
        <Footer menu={footerData.menuItems} />
        {/* <Popup /> */}
      </SiteContainer>
      {cta?.callToAction && <CtaBar cta={cta} />}
    </>
  );
}
