import React from 'react'
import { Input, Box } from "@chakra-ui/react"
import { ErrorMessage, useField } from "formik"

const styles = {
    _placeholder: {
        textTransform: "uppercase"
    }
}



const TextField = ({ label, ...props }) => {

    const errMsg = ({children}) => (
        <Box
            color="red"
            fontSize="xs"
        >
            {children}
        </Box>
    )

    const [field, meta] = useField(props)
    if (meta.touched && meta.error) {const isInvalid = true} else { const isInvalid = false}
    return (
        <>
        <Input bgColor="white" border="none" sx={styles} {...field} {...props} isInvalid={meta.touched && meta.error} />
        <ErrorMessage component={errMsg} name={field.name} />
        </>
    )
}

export default TextField
