import { Box } from "@chakra-ui/react"
import AppForm from "./Form/AppForm"

const SideBar = () => {
    return (
        <Box
        display={{ base: "none", lg: "flex" }}
        width={1 / 4}
        height="100%"
        >
            <Box w="100%" borderRadius="5px" overflow="hidden">
            <AppForm />
            </Box>
        </Box>
    )
}

export default SideBar
