import Link from "next/link";
import { default as React } from "react";
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom";
import { Box } from "@chakra-ui/react";

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount > 0 ? (
    <Box display="flex" justifyContent="flex-end" className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </Box>
  ) : null;
});

const PageHit = ({ hit }) => (
  <div>
    <Link href={hit.uri}>
      <a>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </a>
    </Link>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Box
      as={Hits}
      sx={{
        ul: {
          listStyle: "none",
          marginLeft: 0,
        },
        "li.ais-Hits-item": {
          marginBottom: "1em",
          a: {
            color: "050505",
            h4: {
              marginBottom: "0.2em",
            },
          },
        },
      }}
      className="Hits"
      hitComponent={PageHit}
    />
  </Index>
);

const SearchResult = ({ indices, className, show }) => (
  <Box
    display={show ? `block` : `none`}
    position="absolute"
    right="0"
    top="100%"
    w="80vw"
    maxW="30rem"
    maxH="80vh"
    padding="1rem"
    marginTop="0.5rem"
    overflowY="scroll"
    boxShadow="0 0 5px 0"
    borderRadius="2px"
    zIndex="9"
    bgColor="white"
    sx={{
      ".ais-PoweredBy": {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "80%",
        svg: {
          width: "70px",
        },
      },
    }}
  >
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </Box>
);

export default SearchResult;
