import { Formik, Form, Field, ErrorMessage } from "formik"
import { Box, VStack, Button, Text, Heading, chakra, AspectRatio } from "@chakra-ui/react"
import axios from "axios";
import TextField from "./TextField"
import * as Yup from "yup"
import { default as NextImage } from "next/image"

const FormImage = chakra(NextImage, {
  baseStyle: {
    width: "80%",
    overflow: "hidden",
  },
  shouldForwardProp: (prop) =>
    ["width", "height", "src", "alt", "layout"].includes(prop),
})

const AppForm = () => {
  const validate = Yup.object({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less"),
    email: Yup.string()
      .email("Please provide a valid email address")
      .required("A valid email address is required"),
  })
  const verifyCallback = response => {
    console.log(response)
  }
  return (
    <Box>
          <Box px={6} py={8} bgColor="gray.700" textAlign="center">
            <Heading color="white" fontSize="1.5rem" fontWeight="600">
              4 out of 5 Drivers Agree
            </Heading>
            <Text color="gray.300" fontSize=".9rem" py="1rem">
              Our Newsletter:
              <br />
              1. Is sent to yout inbox
              <br />
              2. Includes words and pictures
              <br />
            </Text>
            <FormImage
              mx="auto"
              src="/subscribe-banner.png"
              alt="Subscribe"
              layout="responsive"
              width={400}
              height={66}
            />
          </Box>
          <Box px={6} py={8} bgColor="#c9c6b9">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
              }}
              validationSchema={validate}
              onSubmit={async values => {
                const result = await axios.post("/api/newsletter", values );
                console.log(result)
              }}
            >
              {/* <Recaptcha sitekey="6Lf-Yc8UAAAAAICazIJTf_vcvfkc2lW4lczMeyxm"
             render="explicit"
             verifyCallback={verifyCallback}
             /> */}
              {formik => (
                <VStack as={Form} spacing="0.8rem">
                  <TextField
                    size="md"
                    type="text"
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                  />
                  <TextField
                    size="md"
                    type="text"
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                  />
                  <TextField
                    size="md"
                    type="email"
                    label="Email"
                    name="email"
                    placeholder="Email"
                  />
                  <Button
                    size="md"
                    w="100%"
                    colorScheme="green"
                    textTransform="uppercase"
                    type="submit"
                  >
                    Subscribe!
                  </Button>
                </VStack>
              )}
            </Formik>
            <Text
              mt="1.5rem"
              color="gray.600"
              textAlign="center"
              fontSize="0.8rem"
              fontStyle="italic"
            >
              Results may vary.
              <br />
              We're serious but have fun.
              <br />
              Never text and drive.
            </Text>
          </Box>
        </Box>
  )
}

export default AppForm
